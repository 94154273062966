export default {
  'newsTags': [
    'Consultation',
    'Dear CEO letter',
    'Discussion paper',
    'Final notice',
    'Policy statement',
    'Speech',
    'Thematic review'
  ],
  'regulatoryStage': [
    'Pre-level 1',
    'Level 1 (L1)',
    'Level 2 (L2)',
    'Level 3 (L3)',
    'Discussion',
    'Consultation',
    'Draft rules',
    'Final rules',
    'N/A'
  ],
  'sectors': [
    'Asset Management',
    'Banking',
    'Capital Markets',
    'Insurance'
  ],
  'trackingStatus': [
    'Not applicable',
    'Initial indication',
    'Initial discussion/consultation',
    'Draft/concrete proposal',
    'Adopted/final rules',
    'Monitoring/supervision',
    'Implemented, under review'
  ]
}
