<template>
<span v-if="!busy">
  <b-btn v-if="!check" variant="danger" @click="check = true">delete</b-btn>
  <b-card v-if="check">
    <div class="mb-4">Are you sure you want to delete?</div>
      <b-btn variant="danger" @click="deleteVertex">yes, delete</b-btn>
  </b-card>
</span>
</template>

<script>
export default {
  name: 'DeleteVertextSoft',
  props: ['id', 'label', 'partition'],
  data () {
    return {
      busy: false,
      check: false
    }
  },
  methods: {
    deleteVertex: async function () {
      this.$logger.debug('deleteVertex started')
      this.busy = true
      try {
        let apiName = 'cosmos'
        let path = '/vertex-soft'
        let params = {
          body: {
            id: this.id,
            label: this.label,
            partition: this.partition
          }
        }
        let response = await this.$Amplify.API.del(apiName, path, params)
        this.$logger.debug('delete ok', response)
        if (response.success) {
          this.$emit('deleted')
        }
      } catch (e) {
        this.$logger.warn('deleteVertex error', e)
      }
      this.busy = false
    }
  }
}
</script>

<style>
</style>
