export default [
  {
    'country': 'International',
    'alpha2': 'INT',
    'alpha3': 'INT'
  },
  {
    'country': 'European Union',
    'alpha2': 'EU',
    'alpha3': 'EUR'
  },
  {
    'country': 'United Kingdom',
    'alpha2': 'GB',
    'alpha3': 'GBR'
  },
  {
    'country': 'United States of America',
    'alpha2': 'US',
    'alpha3': 'USA'
  },
  {
    'country': 'Afghanistan',
    'alpha2': 'AF',
    'alpha3': 'AFG'
  },
  {
    'country': 'Åland Islands',
    'alpha2': 'AX',
    'alpha3': 'ALA'
  },
  {
    'country': 'Albania',
    'alpha2': 'AL',
    'alpha3': 'ALB'
  },
  {
    'country': 'Algeria',
    'alpha2': 'DZ',
    'alpha3': 'DZA'
  },
  {
    'country': 'American Samoa',
    'alpha2': 'AS',
    'alpha3': 'ASM'
  },
  {
    'country': 'Andorra',
    'alpha2': 'AD',
    'alpha3': 'AND'
  },
  {
    'country': 'Angola',
    'alpha2': 'AO',
    'alpha3': 'AGO'
  },
  {
    'country': 'Anguilla',
    'alpha2': 'AI',
    'alpha3': 'AIA'
  },
  {
    'country': 'Antarctica',
    'alpha2': 'AQ',
    'alpha3': 'ATA'
  },
  {
    'country': 'Antigua and Barbuda',
    'alpha2': 'AG',
    'alpha3': 'ATG'
  },
  {
    'country': 'Argentina',
    'alpha2': 'AR',
    'alpha3': 'ARG'
  },
  {
    'country': 'Armenia',
    'alpha2': 'AM',
    'alpha3': 'ARM'
  },
  {
    'country': 'Aruba',
    'alpha2': 'AW',
    'alpha3': 'ABW'
  },
  {
    'country': 'Australia',
    'alpha2': 'AU',
    'alpha3': 'AUS'
  },
  {
    'country': 'Austria',
    'alpha2': 'AT',
    'alpha3': 'AUT'
  },
  {
    'country': 'Azerbaijan',
    'alpha2': 'AZ',
    'alpha3': 'AZE'
  },
  {
    'country': 'Bahamas',
    'alpha2': 'BS',
    'alpha3': 'BHS'
  },
  {
    'country': 'Bahrain',
    'alpha2': 'BH',
    'alpha3': 'BHR'
  },
  {
    'country': 'Bangladesh',
    'alpha2': 'BD',
    'alpha3': 'BGD'
  },
  {
    'country': 'Barbados',
    'alpha2': 'BB',
    'alpha3': 'BRB'
  },
  {
    'country': 'Belarus',
    'alpha2': 'BY',
    'alpha3': 'BLR'
  },
  {
    'country': 'Belgium',
    'alpha2': 'BE',
    'alpha3': 'BEL'
  },
  {
    'country': 'Belize',
    'alpha2': 'BZ',
    'alpha3': 'BLZ'
  },
  {
    'country': 'Benin',
    'alpha2': 'BJ',
    'alpha3': 'BEN'
  },
  {
    'country': 'Bermuda',
    'alpha2': 'BM',
    'alpha3': 'BMU'
  },
  {
    'country': 'Bhutan',
    'alpha2': 'BT',
    'alpha3': 'BTN'
  },
  {
    'country': 'Bolivia',
    'alpha2': 'BO',
    'alpha3': 'BOL'
  },
  {
    'country': 'Bonaire, Sint Eustatius and Saba',
    'alpha2': 'BQ',
    'alpha3': 'BES'
  },
  {
    'country': 'Bosnia and Herzegovina',
    'alpha2': 'BA',
    'alpha3': 'BIH'
  },
  {
    'country': 'Botswana',
    'alpha2': 'BW',
    'alpha3': 'BWA'
  },
  {
    'country': 'Bouvet Island',
    'alpha2': 'BV',
    'alpha3': 'BVT'
  },
  {
    'country': 'Brazil',
    'alpha2': 'BR',
    'alpha3': 'BRA'
  },
  {
    'country': 'British Indian Ocean Territory',
    'alpha2': 'IO',
    'alpha3': 'IOT'
  },
  {
    'country': 'Brunei Darussalam',
    'alpha2': 'BN',
    'alpha3': 'BRN'
  },
  {
    'country': 'Bulgaria',
    'alpha2': 'BG',
    'alpha3': 'BGR'
  },
  {
    'country': 'Burkina Faso',
    'alpha2': 'BF',
    'alpha3': 'BFA'
  },
  {
    'country': 'Burundi',
    'alpha2': 'BI',
    'alpha3': 'BDI'
  },
  {
    'country': 'Cambodia',
    'alpha2': 'KH',
    'alpha3': 'KHM'
  },
  {
    'country': 'Cameroon',
    'alpha2': 'CM',
    'alpha3': 'CMR'
  },
  {
    'country': 'Canada',
    'alpha2': 'CA',
    'alpha3': 'CAN'
  },
  {
    'country': 'Cape Verde',
    'alpha2': 'CV',
    'alpha3': 'CPV'
  },
  {
    'country': 'Cayman Islands',
    'alpha2': 'KY',
    'alpha3': 'CYM'
  },
  {
    'country': 'Central African Republic',
    'alpha2': 'CF',
    'alpha3': 'CAF'
  },
  {
    'country': 'Chad',
    'alpha2': 'TD',
    'alpha3': 'TCD'
  },
  {
    'country': 'Chile',
    'alpha2': 'CL',
    'alpha3': 'CHL'
  },
  {
    'country': 'China',
    'alpha2': 'CN',
    'alpha3': 'CHN'
  },
  {
    'country': 'Christmas Island',
    'alpha2': 'CX',
    'alpha3': 'CXR'
  },
  {
    'country': 'Cocos (Keeling) Islands',
    'alpha2': 'CC',
    'alpha3': 'CCK'
  },
  {
    'country': 'Colombia',
    'alpha2': 'CO',
    'alpha3': 'COL'
  },
  {
    'country': 'Comoros',
    'alpha2': 'KM',
    'alpha3': 'COM'
  },
  {
    'country': 'Congo',
    'alpha2': 'CG',
    'alpha3': 'COG'
  },
  {
    'country': 'Congo, the Democratic Republic of the',
    'alpha2': 'CD',
    'alpha3': 'COD'
  },
  {
    'country': 'Cook Islands',
    'alpha2': 'CK',
    'alpha3': 'COK'
  },
  {
    'country': 'Costa Rica',
    'alpha2': 'CR',
    'alpha3': 'CRI'
  },
  {
    'country': 'Cote D\'Ivoire',
    'alpha2': 'CI',
    'alpha3': 'CIV'
  },
  {
    'country': 'Croatia',
    'alpha2': 'HR',
    'alpha3': 'HRV'
  },
  {
    'country': 'Cuba',
    'alpha2': 'CU',
    'alpha3': 'CUB'
  },
  {
    'country': 'Curaçao',
    'alpha2': 'CW',
    'alpha3': 'CUW'
  },
  {
    'country': 'Cyprus',
    'alpha2': 'CY',
    'alpha3': 'CYP'
  },
  {
    'country': 'Czech Republic',
    'alpha2': 'CZ',
    'alpha3': 'CZE'
  },
  {
    'country': 'Denmark',
    'alpha2': 'DK',
    'alpha3': 'DNK'
  },
  {
    'country': 'Djibouti',
    'alpha2': 'DJ',
    'alpha3': 'DJI'
  },
  {
    'country': 'Dominica',
    'alpha2': 'DM',
    'alpha3': 'DMA'
  },
  {
    'country': 'Dominican Republic',
    'alpha2': 'DO',
    'alpha3': 'DOM'
  },
  {
    'country': 'Ecuador',
    'alpha2': 'EC',
    'alpha3': 'ECU'
  },
  {
    'country': 'Egypt',
    'alpha2': 'EG',
    'alpha3': 'EGY'
  },
  {
    'country': 'El Salvador',
    'alpha2': 'SV',
    'alpha3': 'SLV'
  },
  {
    'country': 'Equatorial Guinea',
    'alpha2': 'GQ',
    'alpha3': 'GNQ'
  },
  {
    'country': 'Eritrea',
    'alpha2': 'ER',
    'alpha3': 'ERI'
  },
  {
    'country': 'Estonia',
    'alpha2': 'EE',
    'alpha3': 'EST'
  },
  {
    'country': 'Ethiopia',
    'alpha2': 'ET',
    'alpha3': 'ETH'
  },
  {
    'country': 'Falkland Islands (Malvinas)',
    'alpha2': 'FK',
    'alpha3': 'FLK'
  },
  {
    'country': 'Faroe Islands',
    'alpha2': 'FO',
    'alpha3': 'FRO'
  },
  {
    'country': 'Fiji',
    'alpha2': 'FJ',
    'alpha3': 'FJI'
  },
  {
    'country': 'Finland',
    'alpha2': 'FI',
    'alpha3': 'FIN'
  },
  {
    'country': 'France',
    'alpha2': 'FR',
    'alpha3': 'FRA'
  },
  {
    'country': 'French Guiana',
    'alpha2': 'GF',
    'alpha3': 'GUF'
  },
  {
    'country': 'French Polynesia',
    'alpha2': 'PF',
    'alpha3': 'PYF'
  },
  {
    'country': 'French Southern Territories',
    'alpha2': 'TF',
    'alpha3': 'ATF'
  },
  {
    'country': 'Gabon',
    'alpha2': 'GA',
    'alpha3': 'GAB'
  },
  {
    'country': 'Gambia',
    'alpha2': 'GM',
    'alpha3': 'GMB'
  },
  {
    'country': 'Georgia',
    'alpha2': 'GE',
    'alpha3': 'GEO'
  },
  {
    'country': 'Germany',
    'alpha2': 'DE',
    'alpha3': 'DEU'
  },
  {
    'country': 'Ghana',
    'alpha2': 'GH',
    'alpha3': 'GHA'
  },
  {
    'country': 'Gibraltar',
    'alpha2': 'GI',
    'alpha3': 'GIB'
  },
  {
    'country': 'Greece',
    'alpha2': 'GR',
    'alpha3': 'GRC'
  },
  {
    'country': 'Greenland',
    'alpha2': 'GL',
    'alpha3': 'GRL'
  },
  {
    'country': 'Grenada',
    'alpha2': 'GD',
    'alpha3': 'GRD'
  },
  {
    'country': 'Guadeloupe',
    'alpha2': 'GP',
    'alpha3': 'GLP'
  },
  {
    'country': 'Guam',
    'alpha2': 'GU',
    'alpha3': 'GUM'
  },
  {
    'country': 'Guatemala',
    'alpha2': 'GT',
    'alpha3': 'GTM'
  },
  {
    'country': 'Guernsey',
    'alpha2': 'GG',
    'alpha3': 'GGY'
  },
  {
    'country': 'Guinea',
    'alpha2': 'GN',
    'alpha3': 'GIN'
  },
  {
    'country': 'Guinea-Bissau',
    'alpha2': 'GW',
    'alpha3': 'GNB'
  },
  {
    'country': 'Guyana',
    'alpha2': 'GY',
    'alpha3': 'GUY'
  },
  {
    'country': 'Haiti',
    'alpha2': 'HT',
    'alpha3': 'HTI'
  },
  {
    'country': 'Heard Island and Mcdonald Islands',
    'alpha2': 'HM',
    'alpha3': 'HMD'
  },
  {
    'country': 'Holy See (Vatican City State)',
    'alpha2': 'VA',
    'alpha3': 'VAT'
  },
  {
    'country': 'Honduras',
    'alpha2': 'HN',
    'alpha3': 'HND'
  },
  {
    'country': 'Hong Kong',
    'alpha2': 'HK',
    'alpha3': 'HKG'
  },
  {
    'country': 'Hungary',
    'alpha2': 'HU',
    'alpha3': 'HUN'
  },
  {
    'country': 'Iceland',
    'alpha2': 'IS',
    'alpha3': 'ISL'
  },
  {
    'country': 'India',
    'alpha2': 'IN',
    'alpha3': 'IND'
  },
  {
    'country': 'Indonesia',
    'alpha2': 'ID',
    'alpha3': 'IDN'
  },
  {
    'country': 'Iran, Islamic Republic of',
    'alpha2': 'IR',
    'alpha3': 'IRN'
  },
  {
    'country': 'Iraq',
    'alpha2': 'IQ',
    'alpha3': 'IRQ'
  },
  {
    'country': 'Ireland',
    'alpha2': 'IE',
    'alpha3': 'IRL'
  },
  {
    'country': 'Isle of Man',
    'alpha2': 'IM',
    'alpha3': 'IMN'
  },
  {
    'country': 'Israel',
    'alpha2': 'IL',
    'alpha3': 'ISR'
  },
  {
    'country': 'Italy',
    'alpha2': 'IT',
    'alpha3': 'ITA'
  },
  {
    'country': 'Jamaica',
    'alpha2': 'JM',
    'alpha3': 'JAM'
  },
  {
    'country': 'Japan',
    'alpha2': 'JP',
    'alpha3': 'JPN'
  },
  {
    'country': 'Jersey',
    'alpha2': 'JE',
    'alpha3': 'JEY'
  },
  {
    'country': 'Jordan',
    'alpha2': 'JO',
    'alpha3': 'JOR'
  },
  {
    'country': 'Kazakhstan',
    'alpha2': 'KZ',
    'alpha3': 'KAZ'
  },
  {
    'country': 'Kenya',
    'alpha2': 'KE',
    'alpha3': 'KEN'
  },
  {
    'country': 'Kiribati',
    'alpha2': 'KI',
    'alpha3': 'KIR'
  },
  {
    'country': 'North Korea',
    'alpha2': 'KP',
    'alpha3': 'PRK'
  },
  {
    'country': 'South Korea',
    'alpha2': 'KR',
    'alpha3': 'KOR'
  },
  {
    'country': 'Kuwait',
    'alpha2': 'KW',
    'alpha3': 'KWT'
  },
  {
    'country': 'Kyrgyzstan',
    'alpha2': 'KG',
    'alpha3': 'KGZ'
  },
  {
    'country': 'Lao People\'s Democratic Republic',
    'alpha2': 'LA',
    'alpha3': 'LAO'
  },
  {
    'country': 'Latvia',
    'alpha2': 'LV',
    'alpha3': 'LVA'
  },
  {
    'country': 'Lebanon',
    'alpha2': 'LB',
    'alpha3': 'LBN'
  },
  {
    'country': 'Lesotho',
    'alpha2': 'LS',
    'alpha3': 'LSO'
  },
  {
    'country': 'Liberia',
    'alpha2': 'LR',
    'alpha3': 'LBR'
  },
  {
    'country': 'Libya',
    'alpha2': 'LY',
    'alpha3': 'LBY'
  },
  {
    'country': 'Liechtenstein',
    'alpha2': 'LI',
    'alpha3': 'LIE'
  },
  {
    'country': 'Lithuania',
    'alpha2': 'LT',
    'alpha3': 'LTU'
  },
  {
    'country': 'Luxembourg',
    'alpha2': 'LU',
    'alpha3': 'LUX'
  },
  {
    'country': 'Macao',
    'alpha2': 'MO',
    'alpha3': 'MAC'
  },
  {
    'country': 'Macedonia, the Former Yugoslav Republic of',
    'alpha2': 'MK',
    'alpha3': 'MKD'
  },
  {
    'country': 'Madagascar',
    'alpha2': 'MG',
    'alpha3': 'MDG'
  },
  {
    'country': 'Malawi',
    'alpha2': 'MW',
    'alpha3': 'MWI'
  },
  {
    'country': 'Malaysia',
    'alpha2': 'MY',
    'alpha3': 'MYS'
  },
  {
    'country': 'Maldives',
    'alpha2': 'MV',
    'alpha3': 'MDV'
  },
  {
    'country': 'Mali',
    'alpha2': 'ML',
    'alpha3': 'MLI'
  },
  {
    'country': 'Malta',
    'alpha2': 'MT',
    'alpha3': 'MLT'
  },
  {
    'country': 'Marshall Islands',
    'alpha2': 'MH',
    'alpha3': 'MHL'
  },
  {
    'country': 'Martinique',
    'alpha2': 'MQ',
    'alpha3': 'MTQ'
  },
  {
    'country': 'Mauritania',
    'alpha2': 'MR',
    'alpha3': 'MRT'
  },
  {
    'country': 'Mauritius',
    'alpha2': 'MU',
    'alpha3': 'MUS'
  },
  {
    'country': 'Mayotte',
    'alpha2': 'YT',
    'alpha3': 'MYT'
  },
  {
    'country': 'Mexico',
    'alpha2': 'MX',
    'alpha3': 'MEX'
  },
  {
    'country': 'Micronesia, Federated States of',
    'alpha2': 'FM',
    'alpha3': 'FSM'
  },
  {
    'country': 'Moldova, Republic of',
    'alpha2': 'MD',
    'alpha3': 'MDA'
  },
  {
    'country': 'Monaco',
    'alpha2': 'MC',
    'alpha3': 'MCO'
  },
  {
    'country': 'Mongolia',
    'alpha2': 'MN',
    'alpha3': 'MNG'
  },
  {
    'country': 'Montenegro',
    'alpha2': 'ME',
    'alpha3': 'MNE'
  },
  {
    'country': 'Montserrat',
    'alpha2': 'MS',
    'alpha3': 'MSR'
  },
  {
    'country': 'Morocco',
    'alpha2': 'MA',
    'alpha3': 'MAR'
  },
  {
    'country': 'Mozambique',
    'alpha2': 'MZ',
    'alpha3': 'MOZ'
  },
  {
    'country': 'Myanmar',
    'alpha2': 'MM',
    'alpha3': 'MMR'
  },
  {
    'country': 'Namibia',
    'alpha2': 'NA',
    'alpha3': 'NAM'
  },
  {
    'country': 'Nauru',
    'alpha2': 'NR',
    'alpha3': 'NRU'
  },
  {
    'country': 'Nepal',
    'alpha2': 'NP',
    'alpha3': 'NPL'
  },
  {
    'country': 'Netherlands',
    'alpha2': 'NL',
    'alpha3': 'NLD'
  },
  {
    'country': 'New Caledonia',
    'alpha2': 'NC',
    'alpha3': 'NCL'
  },
  {
    'country': 'New Zealand',
    'alpha2': 'NZ',
    'alpha3': 'NZL'
  },
  {
    'country': 'Nicaragua',
    'alpha2': 'NI',
    'alpha3': 'NIC'
  },
  {
    'country': 'Niger',
    'alpha2': 'NE',
    'alpha3': 'NER'
  },
  {
    'country': 'Nigeria',
    'alpha2': 'NG',
    'alpha3': 'NGA'
  },
  {
    'country': 'Niue',
    'alpha2': 'NU',
    'alpha3': 'NIU'
  },
  {
    'country': 'Norfolk Island',
    'alpha2': 'NF',
    'alpha3': 'NFK'
  },
  {
    'country': 'Northern Mariana Islands',
    'alpha2': 'MP',
    'alpha3': 'MNP'
  },
  {
    'country': 'Norway',
    'alpha2': 'NO',
    'alpha3': 'NOR'
  },
  {
    'country': 'Oman',
    'alpha2': 'OM',
    'alpha3': 'OMN'
  },
  {
    'country': 'Pakistan',
    'alpha2': 'PK',
    'alpha3': 'PAK'
  },
  {
    'country': 'Palau',
    'alpha2': 'PW',
    'alpha3': 'PLW'
  },
  {
    'country': 'Palestinian Territory, Occupied',
    'alpha2': 'PS',
    'alpha3': 'PSE'
  },
  {
    'country': 'Panama',
    'alpha2': 'PA',
    'alpha3': 'PAN'
  },
  {
    'country': 'Papua New Guinea',
    'alpha2': 'PG',
    'alpha3': 'PNG'
  },
  {
    'country': 'Paraguay',
    'alpha2': 'PY',
    'alpha3': 'PRY'
  },
  {
    'country': 'Peru',
    'alpha2': 'PE',
    'alpha3': 'PER'
  },
  {
    'country': 'Philippines',
    'alpha2': 'PH',
    'alpha3': 'PHL'
  },
  {
    'country': 'Pitcairn',
    'alpha2': 'PN',
    'alpha3': 'PCN'
  },
  {
    'country': 'Poland',
    'alpha2': 'PL',
    'alpha3': 'POL'
  },
  {
    'country': 'Portugal',
    'alpha2': 'PT',
    'alpha3': 'PRT'
  },
  {
    'country': 'Puerto Rico',
    'alpha2': 'PR',
    'alpha3': 'PRI'
  },
  {
    'country': 'Qatar',
    'alpha2': 'QA',
    'alpha3': 'QAT'
  },
  {
    'country': 'Reunion',
    'alpha2': 'RE',
    'alpha3': 'REU'
  },
  {
    'country': 'Romania',
    'alpha2': 'RO',
    'alpha3': 'ROU'
  },
  {
    'country': 'Russian Federation',
    'alpha2': 'RU',
    'alpha3': 'RUS'
  },
  {
    'country': 'Rwanda',
    'alpha2': 'RW',
    'alpha3': 'RWA'
  },
  {
    'country': 'Saint Barthélemy',
    'alpha2': 'BL',
    'alpha3': 'BLM'
  },
  {
    'country': 'Saint Helena',
    'alpha2': 'SH',
    'alpha3': 'SHN'
  },
  {
    'country': 'Saint Kitts and Nevis',
    'alpha2': 'KN',
    'alpha3': 'KNA'
  },
  {
    'country': 'Saint Lucia',
    'alpha2': 'LC',
    'alpha3': 'LCA'
  },
  {
    'country': 'Saint Martin (French part)',
    'alpha2': 'MF',
    'alpha3': 'MAF'
  },
  {
    'country': 'Saint Pierre and Miquelon',
    'alpha2': 'PM',
    'alpha3': 'SPM'
  },
  {
    'country': 'Saint Vincent and the Grenadines',
    'alpha2': 'VC',
    'alpha3': 'VCT'
  },
  {
    'country': 'Samoa',
    'alpha2': 'WS',
    'alpha3': 'WSM'
  },
  {
    'country': 'San Marino',
    'alpha2': 'SM',
    'alpha3': 'SMR'
  },
  {
    'country': 'Sao Tome and Principe',
    'alpha2': 'ST',
    'alpha3': 'STP'
  },
  {
    'country': 'Saudi Arabia',
    'alpha2': 'SA',
    'alpha3': 'SAU'
  },
  {
    'country': 'Senegal',
    'alpha2': 'SN',
    'alpha3': 'SEN'
  },
  {
    'country': 'Serbia',
    'alpha2': 'RS',
    'alpha3': 'SRB'
  },
  {
    'country': 'Seychelles',
    'alpha2': 'SC',
    'alpha3': 'SYC'
  },
  {
    'country': 'Sierra Leone',
    'alpha2': 'SL',
    'alpha3': 'SLE'
  },
  {
    'country': 'Singapore',
    'alpha2': 'SG',
    'alpha3': 'SGP'
  },
  {
    'country': 'Sint Maarten (Dutch part)',
    'alpha2': 'SX',
    'alpha3': 'SXM'
  },
  {
    'country': 'Slovakia',
    'alpha2': 'SK',
    'alpha3': 'SVK'
  },
  {
    'country': 'Slovenia',
    'alpha2': 'SI',
    'alpha3': 'SVN'
  },
  {
    'country': 'Solomon Islands',
    'alpha2': 'SB',
    'alpha3': 'SLB'
  },
  {
    'country': 'Somalia',
    'alpha2': 'SO',
    'alpha3': 'SOM'
  },
  {
    'country': 'South Africa',
    'alpha2': 'ZA',
    'alpha3': 'ZAF'
  },
  {
    'country': 'South Georgia and the South Sandwich Islands',
    'alpha2': 'GS',
    'alpha3': 'SGS'
  },
  {
    'country': 'South Sudan',
    'alpha2': 'SS',
    'alpha3': 'SSD'
  },
  {
    'country': 'Spain',
    'alpha2': 'ES',
    'alpha3': 'ESP'
  },
  {
    'country': 'Sri Lanka',
    'alpha2': 'LK',
    'alpha3': 'LKA'
  },
  {
    'country': 'Sudan',
    'alpha2': 'SD',
    'alpha3': 'SDN'
  },
  {
    'country': 'Suriname',
    'alpha2': 'SR',
    'alpha3': 'SUR'
  },
  {
    'country': 'Svalbard and Jan Mayen',
    'alpha2': 'SJ',
    'alpha3': 'SJM'
  },
  {
    'country': 'Swaziland',
    'alpha2': 'SZ',
    'alpha3': 'SWZ'
  },
  {
    'country': 'Sweden',
    'alpha2': 'SE',
    'alpha3': 'SWE'
  },
  {
    'country': 'Switzerland',
    'alpha2': 'CH',
    'alpha3': 'CHE'
  },
  {
    'country': 'Syrian Arab Republic',
    'alpha2': 'SY',
    'alpha3': 'SYR'
  },
  {
    'country': 'Taiwan',
    'alpha2': 'TW',
    'alpha3': 'TWN'
  },
  {
    'country': 'Tajikistan',
    'alpha2': 'TJ',
    'alpha3': 'TJK'
  },
  {
    'country': 'Tanzania, United Republic of',
    'alpha2': 'TZ',
    'alpha3': 'TZA'
  },
  {
    'country': 'Thailand',
    'alpha2': 'TH',
    'alpha3': 'THA'
  },
  {
    'country': 'Timor-Leste',
    'alpha2': 'TL',
    'alpha3': 'TLS'
  },
  {
    'country': 'Togo',
    'alpha2': 'TG',
    'alpha3': 'TGO'
  },
  {
    'country': 'Tokelau',
    'alpha2': 'TK',
    'alpha3': 'TKL'
  },
  {
    'country': 'Tonga',
    'alpha2': 'TO',
    'alpha3': 'TON'
  },
  {
    'country': 'Trinidad and Tobago',
    'alpha2': 'TT',
    'alpha3': 'TTO'
  },
  {
    'country': 'Tunisia',
    'alpha2': 'TN',
    'alpha3': 'TUN'
  },
  {
    'country': 'Turkey',
    'alpha2': 'TR',
    'alpha3': 'TUR'
  },
  {
    'country': 'Turkmenistan',
    'alpha2': 'TM',
    'alpha3': 'TKM'
  },
  {
    'country': 'Turks and Caicos Islands',
    'alpha2': 'TC',
    'alpha3': 'TCA'
  },
  {
    'country': 'Tuvalu',
    'alpha2': 'TV',
    'alpha3': 'TUV'
  },
  {
    'country': 'Uganda',
    'alpha2': 'UG',
    'alpha3': 'UGA'
  },
  {
    'country': 'Ukraine',
    'alpha2': 'UA',
    'alpha3': 'UKR'
  },
  {
    'country': 'United Arab Emirates',
    'alpha2': 'AE',
    'alpha3': 'ARE'
  },
  {
    'country': 'United States Minor Outlying Islands',
    'alpha2': 'UM',
    'alpha3': 'UMI'
  },
  {
    'country': 'Uruguay',
    'alpha2': 'UY',
    'alpha3': 'URY'
  },
  {
    'country': 'Uzbekistan',
    'alpha2': 'UZ',
    'alpha3': 'UZB'
  },
  {
    'country': 'Vanuatu',
    'alpha2': 'VU',
    'alpha3': 'VUT'
  },
  {
    'country': 'Venezuela',
    'alpha2': 'VE',
    'alpha3': 'VEN'
  },
  {
    'country': 'Viet Nam',
    'alpha2': 'VN',
    'alpha3': 'VNM'
  },
  {
    'country': 'Virgin Islands, British',
    'alpha2': 'VG',
    'alpha3': 'VGB'
  },
  {
    'country': 'Virgin Islands, U.S.',
    'alpha2': 'VI',
    'alpha3': 'VIR'
  },
  {
    'country': 'Wallis and Futuna',
    'alpha2': 'WF',
    'alpha3': 'WLF'
  },
  {
    'country': 'Western Sahara',
    'alpha2': 'EH',
    'alpha3': 'ESH'
  },
  {
    'country': 'Yemen',
    'alpha2': 'YE',
    'alpha3': 'YEM'
  },
  {
    'country': 'Zambia',
    'alpha2': 'ZM',
    'alpha3': 'ZMB'
  },
  {
    'country': 'Zimbabwe',
    'alpha2': 'ZW',
    'alpha3': 'ZWE'
  },
  {
    'country': 'Kosovo',
    'alpha2': 'XK',
    'alpha3': 'XKX'
  }
]
